(function () {
    'use strict';

    angular.module('informaApp')
        .directive('infModalFilter', ['ModalHelper', 'NavigatorService', '$timeout', '$filter', Filter]);

    function Filter(ModalHelper, NavigatorService, $timeout, $filter) {
        return {
            restrict: 'E',
            templateUrl: 'directives/home/inf-filter/template.ptl.html',
            scope: {
                options: '=',
                modalId: '@',
                label: '@',
                isLarge: '@',
                unselectedItemsToggleVisible: '@',
                blocked: '=',
                onSelectedItemsChange: '=',
                onExcludedItemsChange: '=',
                globalSearchShown: '@',
                query: '=',
                subLink: '=',
                onLoad: '='
            },
            link: function (scope, element) {
                scope.$watch('options', function (newValue) {
                    if (newValue) {
                        scope.filteredData = scope.options.length > 1 ? filterByIndicationName(scope.options) : scope.options;

                        var bootstrapColumnClass = 'col-sm-' + (12 / scope.options.length);

                        scope.columnClass = {};
                        scope.columnClass[bootstrapColumnClass] = true;
                    }
                });

                scope.onFilterReady = function (data, key) {
                    if (scope.query && data.length && data.every(function(x) { return !x.highlighted; })) {
                        scope.filteredData[key].highlightItem($filter('orderBy')(data, 'name')[0], true);
                    }
                };

                scope.$watch('query', function (newValue) {
                    element.find('.global-search input').val(newValue);
                });

                scope.onQueryChange = function (value) {
                    scope.query = value;

                    if (!scope.options) {
                        return;
                    }

                    scope.filteredData = filterByIndicationName(scope.options);

                    _.forEach(scope.filteredData, function (x, i) {
                        if (x.source && !x.source.some(function(item) { return item.highlighted; })) {
                            scope.options[i].source.forEach(function(item) { item.highlighted = false; });
                        }
                    });
                }

                scope.switchUnselectedDiseases = function () {
                    scope.hideUnselectedDiseases = !scope.hideUnselectedDiseases;

                    if (scope.filteredData) {
                        for (var i = 0; i < scope.filteredData.length; i++) {
                            scope.filteredData[i].hideUnselectedDiseases = scope.hideUnselectedDiseases;
                            scope.filteredData[i].render();
                        }
                    }
                }

                scope.hideModal = function (callback) {
                    if (scope.blocked) {
                        return;
                    }

                    ModalHelper.hideModal('#' + scope.modalId, callback);
                };

                scope.preventOnSelectedItemsChange = function (key) {
                    if (key < scope.options.length - 1) {
                        return
                    }

                    if (scope.onSelectedItemsChange) {
                        scope.onSelectedItemsChange(getResultSelectedItemsCount());
                    }

                };

                scope.preventOnExcludedItemsChange = key => {
                    if (key < scope.options.length - 1) {
                        return
                    }

                    if (scope.onExcludedItemsChange) {
                        scope.onExcludedItemsChange(getExcludedItemsCount());
                    }
                };

                scope.redirectToLink = function (link) {
                    scope.hideModal(function () {
                        NavigatorService.OpenCustomPage(link);
                    });
                };

                scope.$watch('onLoad', () => {
                    if (scope.onLoad) {
                        scope.onLoad({
                            reset: () => scope.filtersLists && scope.filtersLists.forEach(x => x.reset())
                        });
                    }
                });

                scope.filtersLists = [];
                scope.onFilterLoad = (options) => {
                    scope.filtersLists.push(options);
                };

                function getResultSelectedItemsCount() {
                    var option = scope.options[scope.options.length - 1];

                    if (!option || !option.source) {
                        return 0;
                    }

                    return option.source.filter(function (x) {
                        return x.selected;
                    }).length;
                }

                function getExcludedItemsCount() {
                    const options = scope.options[scope.options.length - 1];

                    return !options || !options.source
                        ? 0
                        : options.source.filter(x => x.excluded).length;
                }

                function filterByIndicationName(data) {
                    if (data[data.length - 1].source) {
                        var lastSource = data[data.length - 1].source.filter(function (x) {
                            return !scope.query || x.name.toLowerCase().indexOf(scope.query.toLowerCase()) !== -1;
                        });

                        var filteredSources = [lastSource];

                        _.forEach(data.slice(0, -1).reverse(), function (x) {
                            var lastItem = filteredSources[filteredSources.length - 1];
                            filteredSources.push(x.source.filter(createFilterFunction(lastItem)))
                        });

                        filteredSources = filteredSources.reverse();

                        return data.map(function (x, i) {
                            return _.assign({}, x, {source: filteredSources[i]});
                        });
                    }


                }

                function createFilterFunction(source) {
                    return function (x) {
                        return source.some(function (item) {
                            return item.parentId === x.id;
                        });
                    }
                }

                $timeout(function () {
                    ModalHelper.on(ModalHelper.events.shown, '#' + scope.modalId, function () {
                        _.forEach(scope.filteredData, function (x) {
                            x.render();
                        });
                    })
                });
            }
        }
    }
})();
